var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:JSON.stringify(_vm.newEntry),staticClass:"flix-panel",class:'flix-panel-' + _vm.$getStatusClass(_vm.newEntry.status)},[_c('bookingHeader',{key:_vm.$store.getters.bookingCalendarHash,attrs:{"entry":_vm.newEntry}}),_c('div',{staticClass:"flix-panel-footer flix-html-small"},[_c('changeGroup',{attrs:{"user":_vm.$getUserVariables().user.md5_id,"client":_vm.newEntry}})],1),_c('div',{staticClass:"flix-panel-footer flix-html-small"},[_c('bookingDate',{key:_vm.$store.getters.bookingCalendarHash,attrs:{"entry":_vm.newEntry,"callback":(ret) => {
          _vm.newEntry = ret
        }}})],1),_c('div',{staticClass:"flix-panel-footer flix-html-small"},[(_vm.$manualAppointment(_vm.newEntry))?_c('div',[_c('span',{staticClass:"flix-html-small",domProps:{"innerHTML":_vm._s(_vm.$manualAppointment(_vm.newEntry))}})]):_vm._e(),_c('flixIcon',{attrs:{"id":'user'}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.newEntry.email)}})],1),_c('div',{staticClass:"flix-panel-footer flix-html-small"},[_c('bookingStatus',{key:_vm.$store.getters.bookingCalendarHash,attrs:{"entry":_vm.newEntry,"callback":(ret) => {
          _vm.newEntry = ret
        }}})],1),_c('div',{staticClass:"flix-panel-footer flix-html-small"},[_c('bookingService',{attrs:{"entry":_vm.newEntry}})],1),_c('bookingDetails',{attrs:{"entry":_vm.newEntry}}),_c('div',{staticClass:"flix-panel-footer flix-html-small flix-timer"},[(!_vm.$route.params.entryID)?_c('router-link',{staticClass:"flix-btn flix-btn-xs flix-btn-default",attrs:{"to":_vm.getLink(_vm.newEntry)}},[_c('flixIcon',{attrs:{"id":'cogwheel'}}),_vm._v(" "+_vm._s(_vm.$tc('message.settings', 2))+"  "),_c('flixIcon',{attrs:{"id":'comments'}}),_vm._v("  "),_c('flixIcon',{attrs:{"id":'notes'}}),_vm._v(" "+_vm._s(_vm.newEntry.count_notes)+" ")],1):_vm._e(),_c('div',{staticClass:"flix-form-group"},[_c('bookingTimer',{staticClass:"flix-pull-right",attrs:{"entry":_vm.newEntry}})],1),_vm._m(0),_c('div',{staticClass:"flix-form-group"},[_c('bookingCheckIn',{attrs:{"entry":_vm.newEntry,"callback":(ret) => {
            _vm.newEntry = ret
          }}})],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flix-form-group"},[_c('div',{staticClass:"flix-clearfix"})])
}]

export { render, staticRenderFns }