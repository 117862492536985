<template>
  <div
    class="flix-panel"
    :class="'flix-panel-' + $getStatusClass(newEntry.status)"
    :key="JSON.stringify(newEntry)"
  >
    <bookingHeader
      :key="$store.getters.bookingCalendarHash"
      :entry="newEntry"
    />

    <div class="flix-panel-footer flix-html-small">
      <changeGroup :user="$getUserVariables().user.md5_id" :client="newEntry" />
    </div>

    <div class="flix-panel-footer flix-html-small">
      <bookingDate
        :entry="newEntry"
        :key="$store.getters.bookingCalendarHash"
        :callback="
          (ret) => {
            newEntry = ret
          }
        "
      />
    </div>

    <div class="flix-panel-footer flix-html-small">
      <div v-if="$manualAppointment(newEntry)">
        <span
          v-html="$manualAppointment(newEntry)"
          class="flix-html-small"
        ></span>
      </div>
      <flixIcon :id="'user'" /> <span v-html="newEntry.email"></span>
    </div>

    <div class="flix-panel-footer flix-html-small">
      <bookingStatus
        :entry="newEntry"
        :key="$store.getters.bookingCalendarHash"
        :callback="
          (ret) => {
            newEntry = ret
          }
        "
      />
    </div>

    <div class="flix-panel-footer flix-html-small">
      <bookingService :entry="newEntry" />
    </div>

    <bookingDetails :entry="newEntry" />
    <div class="flix-panel-footer flix-html-small flix-timer">
      <router-link
        v-if="!$route.params.entryID"
        class="flix-btn flix-btn-xs flix-btn-default"
        :to="getLink(newEntry)"
      >
        <flixIcon :id="'cogwheel'" /> {{ $tc('message.settings', 2) }}&nbsp;
        <flixIcon :id="'comments'" />&nbsp; <flixIcon :id="'notes'" />
        {{ newEntry.count_notes }}
      </router-link>
      <div class="flix-form-group">
        <bookingTimer class="flix-pull-right" :entry="newEntry" />
      </div>
      <div class="flix-form-group">
        <div class="flix-clearfix" />
      </div>
      <div class="flix-form-group">
        <bookingCheckIn
          :entry="newEntry"
          :callback="
            (ret) => {
              newEntry = ret
            }
          "
        ></bookingCheckIn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { entry: Object },
  components: {
    bookingHeader() {
      return import('@/components/booking/header')
    },
    bookingDetails() {
      return import('@/components/booking/details')
    },
    bookingTimer() {
      return import('@/components/booking/timer')
    },
    bookingStatus() {
      return import('@/components/booking/status')
    },
    bookingCheckIn() {
      return import('@/components/booking/checkIn/index.vue')
    },
    bookingDate() {
      return import('@/components/booking/changeDate/index')
    },
    bookingService() {
      return import('@/components/booking/service')
    },
    changeGroup() {
      return import('@/components/booking/changeGroup')
    }
  },
  data() {
    return {
      newEntry: JSON.parse(JSON.stringify(this.entry))
    }
  },
  mounted() {},
  methods: {
    getLink(entry) {
      this.variables = this.$getUserVariables()

      var link = {
        name: 'dashboardBookingDetails',
        params: {
          user: this.$store.getters.user.md5_id,
          form: entry.form,
          entryID: entry.md5ID
        }
      }

      if (this.variables.state === 'share') {
        link = {
          name: 'sharesBookingDetails',
          params: {
            user: this.variables.user.md5_id,
            form: entry.form,
            entryID: entry.md5ID
          }
        }
      }

      return link
    }
  }
}
</script>
<style scoped lang="sass">

.flix-panel
  border: 1px solid grey
</style>
